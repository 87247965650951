<template>
  <div class="modal fade text-left" id="ImportPscReportModal" tabindex="-1" role="dialog" aria-labelledby="ImportPscReportModalIdTitle"
       aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <form class="modal-content" @submit.prevent="submitReport">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"><strong>IMPORT PSC INSPECTION REPORT</strong></h5>
            <button type="button" class="close" aria-label="Close" style="font-size: 2em" @click.prevent="cancelImport">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="max-height: 75vh;">
            <div class="row">
              <div class="col-12">
                <label for="PscModalTemplateFile" style="font-weight: bold">IMPORT EXCEL TEMPLATE FILE</label>
                <input type="file" class="form-control-file" id="PscModalTemplateFile" ref="PscModalTemplateFile" accept=".xlsx" required>
                <hr v-if="activate.dz">
              </div>
              <template v-if="activate.dz">
                <div class="col-12" style="height: 48vh; max-height: 48vh; overflow-y: auto" id="BlockDeficiencies"></div>
                <div class="col-12">
                  <hr>
                  + Add Deficiency
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click.prevent="cancelImport">Close</button>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import jQuery from 'jquery'
import DateMixin from "@/mixins/DateMixin"
import AppLoading from "@/components/elements/AppLoading";
import {AlertService} from "@/services/AlertService";
import {ReportService} from "@/services/ReportService";
import {MATCH_ROUTE_NAMES} from "@/services/ConstantService";
// If you are using JavaScript/ECMAScript modules:
import Dropzone from "dropzone";

// eslint-disable-next-line no-undef
let $ = jQuery

export default {
  name: 'ImportPscReportModal',
  data() {
    return {
      activate: {
        dz: false,
      },
      dz: {
        options: {
          url: 'test',
          acceptedFiles: 'image/*',
          uploadMultiple: true,
          autoProcessQueue: false,
          createImageThumbnails: false,
        },
        before: [],
        after: [],
      },
    }
  },
  mixins: [DateMixin],
  components: {
    'loading': AppLoading,
  },
  methods: {
    createDeficiency() {
      console.log('CREATING DEFICIENCY');

      let tplDeficiency = `<div class="row deficiency-block" id="DEFICIENCY_BLOCK_DEFICIENCY_NUMBER" action="/target">
        <div class="col-12 text-center font-weight-bolder mb-2 deficiency-title" style="background-color: #e1e1e1">Deficiency DEFICIENCY_NUMBER</div>
        <div class="col-6">
          <div class="text-center">Before Rectification</div>
          <div class="card card-body bg-light my-2 deficiency-before" style="height: 100px;"></div>
        </div>
        <div class="col-6">
          <div class="text-center">After Rectification</div>
          <div class="card card-body bg-light my-2 deficiency-after" style="height: 100px;"></div>
        </div>
      </div>`;

      let deficiencies = $('#BlockDeficiencies').find('[id^=DEFICIENCY_BLOCK_]');
      let deficiency_number = deficiencies.length + 1;

      $('#BlockDeficiencies').append(tplDeficiency.replaceAll('DEFICIENCY_NUMBER', deficiency_number));

      this.dz.before[deficiency_number] = new Dropzone('#DEFICIENCY_BLOCK_' + deficiency_number + ' .deficiency-before', this.dz.options);
      this.dz.after[deficiency_number] = new Dropzone('#DEFICIENCY_BLOCK_' + deficiency_number + ' .deficiency-after', this.dz.options);
      // $('#BlockDeficiencies').find('#DEFICIENCY_BLOCK_' + deficiency_number).find('.deficiency-before');
    },
    async cancelImport() {
      if (await AlertService.cancelAlert()) {
        $('#ImportPscReportModal').modal('hide');
      }
    },
    async submitReport() {
      swal.fire({
        'title': `Importing PSC Inspection<br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()

      let formData = new FormData();
      formData.append('tplFile', this.$refs.PscModalTemplateFile.files[0])

      const response = await ReportService.importPsc(formData);

      if (response.status == 200) {
        swal.close();

        const alert_success = await AlertService.successAlert(`New PSC Inspection has been created`, 'IMPORT PSC REPORT');
        const target_route = {name: MATCH_ROUTE_NAMES.pscid, params: {id: response.data.id}};

        if (response.data.id, alert_success) {
          setTimeout(() => this.$router.push(target_route), 3000);
        }
      }
    },
  },
  // LIFECYCLE START
  // beforeCreate {},
  // async created() {},
  // beforeMount {},
  // mounted {},
  // beforeUpdate {},
  // updated {},
  // beforeUnmount {},
  // unmounted {},
  // errorCaptured {},
  // renderTracked (Dev only) {},
  // renderTriggered (Dev only) {},
  // activated {},
  // deactivated {},
  // serverPrefetch (SSR only) {},
  // LIFECYCLE END
  computed: {},
  mounted() {
    !this.activate.dz || this.createDeficiency();
    // if (this.activate.dz) {
    //
    // }
  },
}
</script>

<style scoped>

</style>
