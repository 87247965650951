<template>
  <!-- Modal -->
  <div class="modal fade" data-backdrop="static" id="NewPscInspection" role="dialog" aria-labelledby="NewPscInspectionTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="addPscInspection">
        <div class="modal-header">
          <h5 class="modal-title" id="NewPscInspectionTitle">ADD PSC INSPECTION REPORT</h5>
<!--          <button type="button" class="close" @click.prevent="cancelAdd">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
        </div>
        <div class="modal-body">
          <div class="form-group row no-gutters mb-1" v-show="!user.vessel_id">
            <label for="VesselList" class="font-weight-bold col-sm-2 col-form-label text-right pr-4">Vessel</label>
            <div class="col-sm-10">
              <select class="form-control form-control-sm text-uppercase" id="VesselList" v-model="report.vessel_id" required :disabled="user.vessel_id">
                <option :value="null">—— Select Vessel ——</option>
                <option v-for="vessel in vesselKeys" :key="vessel.id" :value="vessel.id">
                  {{ vessel.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="ReportTitle" class="font-weight-bold col-sm-2 col-form-label text-right pr-4">Report Title</label>
            <div class="col-sm-10">
              <textarea class="form-control form-control-sm" id="ReportTitle" rows="3" v-model="report.title" required></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="InspectionDate" class="font-weight-bold col-sm-2 col-form-label text-right pr-4">Inspection Date</label>
            <div class="col-sm-10">
              <input type="date" :min="minimumDate" :max="currentDate" class="form-control form-control-sm" id="InspectionDate"
                     v-model="report.inspection_date" required>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="CountryList" class="font-weight-bold col-sm-2 col-form-label text-right pr-4">Country</label>
            <div class="col-sm-10">
              <select class="form-control form-control-sm text-uppercase" id="CountryList" v-model="report.country_id">
                <option :value="null">—— Select Country ——</option>
                <option v-for="country in countryKeys" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="PortList" class="font-weight-bold col-sm-2 col-form-label text-right pr-4">Port</label>
            <div class="col-sm-10">
              <select class="form-control form-control-sm text-uppercase" id="PortList" v-model="report.port_id" :disabled="!ports.length">
                <option :value="null">—— Select Port ——</option>
                <option v-for="port in ports" :key="port.id" :value="port.id">
                  {{ port.name }} {{ port.locode_wrap }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" @click.prevent="cancelAdd">Close</button>
          <button type="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" class="btn btn-sm btn-primary" :disabled="hasChanges === false">Add Report</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import jQuery from 'jquery'
import DateMixin from "@/mixins/DateMixin"
import {AlertService} from '../../services/AlertService'
import {DataService} from "@/services/DataService";
// import {PSC_INDEX} from "../../services/ConstantService";
// import {GlobalService} from "../../services/GlobalService";

// eslint-disable-next-line no-undef
let $ = jQuery

export default {
  name: 'NewPscInspectionReport',
  mixins: [DateMixin],
  data() {
    return {
      ports: [],
      report: {},
    }
  },
  methods: {
    // ALREADY REQUESTED FROM PARENT COMPONENT
    // ...mapActions(['addRequestKeys', 'generateRequestKeys']),
    ...mapActions(['createPscReport']),
    getDataTemplate(target_key) {
      const data_templates = {
        report: {
          title: null,
          inspection_date: null,
          vessel_id: this.user.vessel_id,
          country_id: null,
          port_id: null,
        },
      };

      return data_templates.hasOwnProperty(target_key) ? data_templates[target_key] : null;
    },
    getPortsByCountry(id) {
      this.ports = id ? this.portKeys.filter(port => port.country_id === id) : [];
      this.report.port_id = null;
    },
    async addPscInspection() {
      if (await AlertService.confirmCreateAlert()) {
        const response = await this.createPscReport(this.report);

        if (response.status === 200) {
          const psc = response.data.psc;

          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $('.fade').remove();
          $('.show').remove();

          await successAlert.fire({
            title: `NEW PSC INSPECTION REPORT ADDED SUCCESSFULLY`,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            timer: 1500,
            scrollbarPadding: false
          }).then((result) => {
            if (result.isDismissed || result.isConfirmed) {
              this.$router.push({name: 'PscMain', params: {id: psc.main.id}});
            }

            return result;
          });
        }
      }
    },
    async cancelAdd() {
      const response = await AlertService.cancelAlert();

      if (response) {
        $('#NewPscInspection').modal('hide');
        this.report = this.getDataTemplate('report');
      }
    }
  },
  async created() {
    this.report = this.getDataTemplate('report');
    // ALREADY REQUESTED FROM PARENT COMPONENT
    // this.addRequestKeys(['getVesselKeys', 'getTimezoneKeys', 'getEventOneKeys', 'getEventImpactKeys']);
    // await this.generateRequestKeys();
  },
  async mounted() {
    this.report.vessel_id = this.user.vessel_id;
  },
  computed: {
    ...mapGetters(['vesselKeys', 'countryKeys', 'portKeys', 'user']),
    hasChanges() {
      const defaultData = {
        title           : null,
        inspection_date : null,
        vessel_id       : null,
        country_id      : null,
        port_id         : null
      };

      let editedData = {
        title           : this.report.title === '' ? null : this.report.title,
        inspection_date : this.report.inspection_date === '' ? null : this.report.inspection_date,
        vessel_id       : this.report.vessel_id,
        country_id      : this.report.country_id,
        port_id         : this.report.port_id,
      };

      return DataService.checkIfChanged(defaultData, editedData);
    }
  },
  watch: {
    'report.country_id'(id) {
      this.$nextTick(() => {
        this.getPortsByCountry(id);
      })
    }
  }
}
</script>

<style scoped>

</style>
