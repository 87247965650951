<template>
    <div role="button" data-toggle="popover" data-trigger="hover" title="Deficiency Status" data-html="true" :data-content="getDynamicContent()" data-placement="left">
      <template v-if="data.total > 0">
        <template v-if="data.total <= 5">
          <div class="indicator-container">
            <div class="circle" :class="colormap[indicator]" v-for="indicator in indicators"></div>
          </div>
        </template>
        <template v-else>
          <div class="indicator-container" >
            <div class="circle" :class="colormap[indicator]" v-for="indicator in indicators.slice(0, 5)"></div>
            <div class="font-weight-bold">...</div>
          </div>
        </template>
      </template>
    </div>
</template>

<script>
export default {
  name: "DeficiencyStatusIndicator",
  props: {
    data: Object
  },
  data() {
    return {
      colormap: {
        open: 'red',
        closed: 'green',
        readytoClose: 'blue'
      }
    }
  },
  mounted() {
    $('[data-toggle="popover"]').popover()
  },
  computed: {
    indicators: function() {
      if(this.data.total === 0) return []
      return Array(this.data.closed).fill('closed').concat(Array(this.data.open).fill('open')).concat(Array(this.data.readytoClose).fill('readytoClose'))
    },
  },
  methods: {
    getDynamicContent() {

      var content = ''

      if(this.indicators.includes('closed')) {
          content += `<div class="indicator-popover">
                      <div style="margin-right: 7000px">Closed (${this.indicators
                          .filter(indicator => indicator === 'closed').length}):</div>${this.indicators
                          .filter(indicator => indicator === 'closed')
                          .map(indicator => `<div class="circle ${this.colormap[indicator] || 'red'}"></div>`)
                          .join('')}
                      </div>`
      }
      if(this.indicators.includes('open')) {

          content += `<div class="indicator-popover">
                      <div style="width: 7000px">Open (${this.indicators
                        .filter(indicator => indicator === 'open').length}):</div>${this.indicators
                        .filter(indicator => indicator === 'open')
                        .map(indicator => `<div class="circle ${this.colormap[indicator] || 'red'}"></div>`)
                        .join('')}
                      </div>`
      }
      if(this.indicators.includes('readytoClose')) {
          content += `<div class="indicator-popover" v-if="this.indicators.includes('readytoClose')">
                      <div style="width: 7000px">Ready to close (${this.indicators
                        .filter(indicator => indicator === 'readytoClose').length}):</div>${this.indicators
                        .filter(indicator => indicator === 'readytoClose')
                        .map(indicator => `<div class="circle ${this.colormap[indicator] || 'red'}"></div>`)
                        .join('')}
                    </div>`
      }

      return content
    }
  }
}

</script>

<style>

.indicator-container {
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
}
.indicator-popover {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.circle {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50px;
}

.popover-header {
  font-size: 10px;
}

.green {
  background-color: #0db946;
}

.red {
  background-color: #dd231d;
}

.blue {
  background-color: #042454;
}
</style>
